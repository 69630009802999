<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title"
            :float-layout="true"
            :enable-download="false"
            :html-to-pdf-options="htmlToPdfOptions"
            :paginate-elements-by-height="1120"
            :manual-pagination="true"
            :pdf-quality="2"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="750px"
            ref="html2Pdf"
            @beforeDownload="beforeDownload($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <v-col class="pa-0 ma-0 mb-auto">
                            <v-row no-gutters>
                                <v-col class="col-9-5 d-flex flex-column pt-3">
                                    <div>
                                        <span class="header-title">{{ Office__title }}</span>
                                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != null && Office__companyno != '' && Office__companyno != 'NA'">{{ '(' + Office__companyno + ')' }}</span>
                                    </div>
                                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(word => word != null).join(', ')  }}</span>
                                    <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__contractemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                                </v-col>
                                <v-col class="col-2-5 text-right">
                                    <img
                                        :src="appLogo"
                                        class="mb-3 mr-3"
                                        width="130px"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ documentTitle }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ Contract__sellertitle }}</span>
                                    <span style="font-weight: normal" v-if="[1,5].includes(Contract__salestype_id)">{{ Contract__office_id != null ? Office__title : null }}</span>
                                    <div v-else-if="[2].includes(Contract__salestype_id)" class="d-flex flex-column">
                                        <span style="font-weight: normal">{{ Supplier__title }}</span>
                                        <span style="font-weight: normal">{{ Supplier__address1 }}</span>
                                        <span style="font-weight: normal">{{ Supplier__address2 }}</span>
                                        <span style="font-weight: normal">{{ Supplier__address3 }}</span>
                                        <span style="font-weight: normal">{{ Supplier__city + ' ' + (Supplier__postcode != null ? Supplier__postcode : '') }}</span>
                                        <span style="font-weight: normal">{{ Supplier__state }}</span>
                                        <span style="font-weight: normal">{{ Supplier__country }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="2" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t('message.marketing') }}</span>
                                    <span>{{ Salesperson__name }}</span>
                                </v-col>
                                <v-col cols="2" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                                    <span>{{ Contract__contractdate ? formatDate(Contract__contractdate) : '' }}</span>
                                </v-col>
                                <v-col cols="2" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold text-right">{{ $t('message.number') }}</span>
                                    <span class="text-right">{{ Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="6" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t(buyerCaption) }}</span>
                                    <span>{{ Customer__title }}</span>
                                    <span>{{ Customer__address1 }}</span>
                                    <span>{{ Customer__address2 }}</span>
                                    <span>{{ Customer__address3 }}</span>
                                    <span>{{ Customer__city + ' ' + (Customer__postcode != null ? Customer__postcode : '')}}</span>
                                    <span>{{ Customer__state }}</span>
                                    <span>{{ Customer__country }}</span>
                                    <span v-html="getTaxId()"></span>
                                </v-col>
                                <v-col cols="6" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t(deliveryCaption) }}</span>
                                    <span v-html="nl2br(Deliveryaddress__address)"></span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="7" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t('message.termsOfPayment') }}</span>
                                    <span>{{ Paymentterm__title }}</span>
                                </v-col>
                                <v-col cols="4" class="d-flex flex-column py-0 offset-1">
                                    <span class="font-weight-bold text-right">{{ $t('message.shipment') }}</span>
                                    <span class="text-right">{{ Contract__shippingweekstatement }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-1">
                                <v-col cols="6" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold" v-if="Contract__showbank == 1">{{ $t('message.paidTo') }}</span>
                                    <template v-if="Contract__showbank == 1">
                                        <span style="white-space: pre" v-html="Bank__id ? contractBank : ''">{{ Bank__id ? contractBank : '' }}</span>
                                        <span style="white-space: pre" v-if="false">{{ Bank__id ? contractBank : '' }}</span>
                                     </template>
                                </v-col>
                                <v-col cols="6" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold text-right">{{ $t('message.countryOriginDestination') }}</span>
                                    <span class="text-right" v-if="Contract__incoterm_id == 1">
                                         {{ Contract__productorigin_id != null ? (Origin__alt_country != null ? Origin__alt_country : Origin__country) : '' }} / {{ Contract__shippingport_id != null ? Shippingport__title + ' - ' : '' }}{{ Contract__destinatione_id != null ? Destination__country : '' }}
                                    </span>
                                    <span class="text-right" v-else>
                                        {{ Contract__productorigin_id != null ? (Origin__alt_country != null ? Origin__alt_country : Origin__country) : '' }} / {{ Contract__destinatione_id != null ? Destination__country : '' }}
                                    </span>
                                    <span class="text-right" v-if="false">
                                        {{ Loadingport__country }} /
                                        {{ Contract__destinatione_id != null ? Destination__country : '' }}
                                    </span>
                                    <span class="font-weight-bold text-right">{{ $t('message.termsOfDelivery') }}</span>
                                    <span class="text-right">{{ contractIncoterm }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="px-0 pb-0 pt-2 ma-0 mb-auto">
                            <v-simple-table
                                class="specification-table"
                                dense
                            >
                                <template v-slot:default>
                                    <tr class="table-header">
                                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.specification') }}</td>
                                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.size') }}</td>
                                        <td class="px-0 font-weight-bold border-bottom-1">{{ $t('message.grade') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-12-pct border-bottom-1">{{ $t('message.quantity') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.unitPrice') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.amount') }}</td>
                                    </tr>
                                    <template v-for="(item,index) in Salesitems">
                                        <template v-if="item.type == 'default'">
                                            <tr class="table-row-main" v-if="index == 0 " :key="'default_1_' + item.Salesitem.id">
                                                <td colspan="6">{{ getDescription(item.Salesdescription.title,item.Mc.id,item.Mc.title) + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) + (Contract__destinatione_id == 4 && Contract__productgroup_id != null ? getHsCode(Contract__productgroup_id) : '') }}</td>
                                            </tr>
                                            <tr class="table-row" v-else-if="index > 0 && Salesitems[index - 1].Salesdescription.title != item.Salesdescription.title" :key="'default_2_' + item.Salesitem.id">
                                                <td colspan="6">{{ getDescription(item.Salesdescription.title,item.Mc.id,item.Mc.title) + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) }}</td>
                                            </tr>
                                            <tr class="table-row-sub" :key="'default_3_' + item.Salesitem.id">
                                                <td></td>
                                                <td class="px-0">{{ item.Size.title }}</td>
                                                <td class="px-0">{{ item.Grade.title }}</td>
                                                <td class="px-0 text-right text-no-wrap">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.itemqty,uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}</td>
                                                <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.unitprice,'0,0.00') : '' }}</td>
                                                <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) + ')' }}</td>
                                            </tr>
                                        </template>
                                        <template v-else-if="item.type == 'othercharge' && item.Salesitem.chargeto == 0">
                                            <tr :key="'othercharge_' + item.Salesitem.id">
                                                <td></td>
                                                <td class="px-0">{{ item.Salesitem.size }}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td class="px-0 text-right">{{ formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) }}</td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
                            <v-simple-table
                                class="specification-table"
                                dense
                            >
                                <template v-slot:default>
                                    <tr>
                                        <td colspan="4" class="px-0">
                                            <div style="white-space: pre-line" v-if="Contract__note != null">
                                                <div v-if="Contract__note.indexOf('Remark') == -1">{{ $t('message.remarks') }}</div>
                                                <div>{{ Contract__note.trim() }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="px-0 font-weight-bold border-bottom-1">
                                            <div class="d-flex flex-column" v-if="[7,8].includes(Contract__fsc) == false && Contract__fsc != null">
                                                <span>{{ $t('message.certCode') + ' : ' + getCertification(Contract__fsc,'cert_no') }}</span>
                                                <span>{{ $t('message.expiryDate') + ' : ' + getCertification(Contract__fsc,'cert_expiry_date') }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold width-15-pct border-bottom-1">
                                            <span class="text-uppercase pr-3">{{ $t('message.total') }}</span>
                                            <span v-if=" [1,2,3,5].includes(Contract__containersize_id) ">{{ Contract__containercount }}</span>
                                            <span v-if=" [1,2,3,5].includes(Contract__containersize_id) "> X </span>
                                            <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == Contract__containersize_id).Containersize.title }}</span>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-12-pct border-bottom-1">
                                            <span v-for="(total, index) in Salesitems__itemsTotalQty" :key="index">
                                                {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                            </span>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ getCurrency(Contract__currency_id,'code') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ formatThisNumber(Salesitems__itemsTotalAmount,currencyFormatPrecision) }}</td>
                                    </tr>
                                    <tr v-if="Contract__salestype_id == 2 && Contract__adjustment > 0">
                                        <td colspan="3" class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ Contract__adjustment_title }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ getCurrency(Contract__currency_id,'code') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ formatThisNumber(Contract__adjustment, currencyFormatPrecision) }}</td>
                                    </tr>
                                    <tr v-if="Contract__salestype_id == 2 && Contract__adjustment > 0">
                                        <td colspan="3" class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ $t('message.grandTotal') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ getCurrency(Contract__currency_id,'code') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ formatThisNumber(Salesitems__itemsGrandTotal, currencyFormatPrecision) }}</td>
                                    </tr>
                                    <tr v-if="$route.name == 'print_proforma_contract' && Payment__dprequired == 1 && Payment__amountinpercent > 0">
                                        <td class="px-0 font-weight-bold text-right border-bottom-1" colspan="3">{{ $t('message.deposit') + ' ' + Payment__amountinpercent + '%' }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ getCurrency(Contract__currency_id,'code') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1">{{ formatThisNumber(depositRequired,currencyFormatPrecision) }}</td>
                                    </tr>
                                </template>
                            </v-simple-table>
                            <template v-if="Contract__longform != 1">
                                <v-row no-gutters class="pt-2">
                                    <v-col cols="12">{{ $t('message.notes') + ':' }}</v-col>
                                    <v-col cols="12" v-for="footerNote in contractFooterNotes" :key="footerNote.id">
                                        <div class="d-flex flex-row align-stretch" v-if="Contract__footer_notes.includes(footerNote.id)">
                                            <span class="px-1">-</span>
                                            <span class="footer-note text-wrap" v-html="footerNote.text"></span>
                                        </div>
                                        <div class="d-flex flex-row align-stretch" v-else-if="Contract__destinatione_id == 1 && footerNote.id == 10">
                                            <span class="px-1">-</span>
                                            <span class="footer-note text-wrap" v-html="footerNote.text"></span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="align-end">
                                    <v-col cols="7" class="d-flex flex-column py-0">
                                        <span class="font-weight-bold">{{ $t('message.confirmedBySeller') }}</span>
                                        <span class="stamp-area text-center pt-2">
                                            <img
                                                :src="(Contract__stampsignatured == 1 ? '/static/img/appic/stamps/' + Office__stamp_ws : '/static/img/appic/stamps/' + Office__stamp)"
                                                height="80px"
                                                v-if="Contract__showsignature != 'N'"
                                            >
                                        </span>
                                        <span class="font-weight-bold border-top-1">{{ $t('message.companyStamp') }}</span>
                                    </v-col>
                                    <v-col cols="5" class="d-flex flex-column py-0">
                                        <span class="font-weight-bold ml-3">{{ $t('message.confirmedByBuyer') }}</span>
                                        <span class="stamp-area"></span>
                                        <span class="font-weight-bold border-top-1 ml-3">{{ '(' + $t('message.pleaseSign') + ')' }}</span>
                                    </v-col>
                                </v-row>
                            </template>
                        </div>
                    </div>
                </v-app>
            </section>
            <section slot="pdf-content" v-if="Contract__longform == 1">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <div class="px-0 pb-0 pt-3 ma-0 mb-auto">
                            <v-row no-gutters class="pt-2">
                                <v-col cols="12">{{ $t('message.notes') + ':' }}</v-col>
                                <v-col cols="12" v-for="footerNote in contractFooterNotes" :key="footerNote.id">
                                    <div class="d-flex flex-row align-stretch" v-if="Contract__footer_notes.includes(footerNote.id)">
                                        <span class="px-1">-</span>
                                        <span class="footer-note text-wrap" v-html="footerNote.text"></span>
                                    </div>
                                    <div class="d-flex flex-row align-stretch" v-else-if="Contract__destinatione_id == 1 && footerNote.id == 10">
                                        <span class="px-1">-</span>
                                        <span class="footer-note text-wrap" v-html="footerNote.text"></span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="align-end">
                                <v-col cols="7" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold">{{ $t('message.confirmedBySeller') }}</span>
                                    <span class="stamp-area text-center pt-2">
                                        <img
                                            :src="(Contract__stampsignatured == 1 ? '/static/img/appic/stamps/' + Office__stamp_ws : '/static/img/appic/stamps/' + Office__stamp)"
                                            height="80px"
                                            v-if="Contract__showsignature != 'N'"
                                        >
                                    </span>
                                    <span class="font-weight-bold border-top-1">{{ $t('message.companyStamp') }}</span>
                                </v-col>
                                <v-col cols="5" class="d-flex flex-column py-0">
                                    <span class="font-weight-bold ml-3">{{ $t('message.confirmedByBuyer') }}</span>
                                    <span class="stamp-area"></span>
                                    <span class="font-weight-bold border-top-1 ml-3">{{ '(' + $t('message.pleaseSign') + ')' }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-app>
            </section>
            <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
        </vue-html2pdf>
    </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
import {formatDate, isNumeric, numberFormat, nl2br, log} from "Helpers/helpers";
import {api} from "Api";
import {
    buyerCaptionTypes,
    // certifications,
    // certificationsNew,
    containerSizes,
    contractFooterNotes,
    // currencies,
    deliveryAddressTypes,
    incoterms,
    offices,
    // productGroups,
    // productGroupsNew,
    uofm
} from "../../../store/modules/appic/constants";
import roundTo from "round-to";
import VueHtml2pdf from 'vue-html2pdf';

export default {
    name: "PrintContractBlob",
    props: ['contractId'],
    components: {VueHtml2pdf},
    data() {
        return {
            appLogo: AppConfig.appSessionLogo,
            documentTitle: this.$t('message.contract'),
            deliveryAddresses: [],
            depositRequired: 0,
            loaded: false,
            buyerCaptionTypes: buyerCaptionTypes,
            // certifications: certifications,
            // certificationsNew: certificationsNew,
            certificationsNew: [],
            containerSizes: containerSizes,
            contractFooterNotes: contractFooterNotes,
            currencies: [],
            // currencies: currencies,
            deliveryAddressTypes: deliveryAddressTypes,
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.70
                },
                html2canvas: {
                    scale: 2
                }
            },
            incoterms: incoterms,
            offices: offices,
            // productGroups: productGroups,
            // productGroupsNew: productGroupsNew,
            productGroupsNew: [],
            uofmQty: uofm.qty,
            Bank__id: null,
            Bank__title: null,
            Bank__account: null,
            Bank__swiftcode: null,
            Bank__beneficiary: null,
            Bank__agent: null,
            Bank__agentswiftcode: null,
            Contract__id: null,
            Contract__adjustment: 0,
            Contract__adjustment_title: '',
            Contract__buyeraddresstype: null,
            Contract__contractdate: null,
            Contract__commission_per_line: null,
            Contract__containercount: null,
            Contract__containersize_id: 1,
            Contract__currency_id: 1,
            Contract__customer_id: null,
            Contract__destinatione_id: null,
            Contract__deliveryaddress_id: null,
            Contract__incoterm_id: null,
            Contract__incotermport: null,
            Contract__fsc: 0,
            Contract__footer_notes: [],
            Contract__loadingport_id: null,
            Contract__longform: 0,
            Contract__margin: null,
            Contract__note: null,
            Contract__office_id: null,
            Contract__paymentstatement_id: null,
            Contract__productgroup_id: null,
            Contract__productorigin_id: null,
            Contract__partial_no: null,
            Contract__revision_no: null,
            Contract__salescontact_id: null,
            Contract__salestype_id: null,
            Contract__sellertitle: null,
            Contract__shippingport_id: null,
            Contract__shipweekdate: null,
            Contract__shippingweekstatement: null,
            Contract__showbank: 1,
            Contract__showsignature: null,
            Contract__stampsignatured: null,
            Contract__supplier_id: null,
            Contract__title: null,
            Contract__version: null,
            Customer__title: null,
            Customer__address1: null,
            Customer__address2: null,
            Customer__address3: null,
            Customer__city: null,
            Customer__gstno: null,
            Customer__postcode: null,
            Customer__state: null,
            Customer__country: null,
            Customer__country_id: 1,
            Customer__taxno_type: null,
            Customer__uscc: null,
            Deliveryaddress__address: null,
            Destination__country: null,
            Loadingport__country: null,
            Loadingport__title: null,
            Office__title: null,
            Office__address1: null,
            Office__address2: null,
            Office__city: null,
            Office__companyno: null,
            Office__contractemail: null,
            Office__contracttel: null,
            Office__country: null,
            Office__postcode: null,
            Office__state: null,
            Office__stamp: 'stampappsdnbhd.png',
            Office__stamp_ws: 'stampsdnbhd.png',
            Office__website: null,
            Origin__alt_country: null,
            Origin__country: null,
            Payment__dprequired: 0,
            Payment__amountinpercent: 0,
            Paymentterm__title: null,
            Salesperson__name: null,
            Salesitems: [],
            Salesitems__itemsGrandTotal: 0,
            Salesitems__itemsTotalAmount: 0,
            Salesitems__itemsTotalQty: [],
            Shippingport__title: null,
            Supplier__title: null,
            Supplier__address1: null,
            Supplier__address2: null,
            Supplier__address3: null,
            Supplier__city: null,
            Supplier__postcode: null,
            Supplier__state: null,
            Supplier__country: null
        }
    },
    computed: {
        buyerCaption() {
            return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_first
        },
        contractIncoterm() {
            let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Contract__incoterm_id)?.Incoterm?.title;
            let incotermport = '';
            switch(this.Contract__incotermport){
                case null:
                    incotermport = ''
                    break;
                case 0: //POD
                    if(this.Contract__shippingport_id != null) {
                        incotermport = ' ' + this.Shippingport__title
                    }
                    break;
                case 1: //POL
                    if(this.Contract__loadingport_id != null) {
                        incotermport = ' ' + this.Loadingport__title
                    }
                    break;
                case 2:
                    incoterm = ''
                    break;
            }
            return incoterm + incotermport;
        },
        contractBank(){
            let bank = '';
            if(this.Bank__id != null){
                if(this.Bank__title){ bank += this.$t('message.banker') + ': ' + this.Bank__title.trim()}
                if(this.Bank__swiftcode){ bank += "\n" +  this.$t('message.swiftCode') + ': ' + this.Bank__swiftcode.trim()}
                if(this.Bank__account){ bank += "\n" + this.$t('message.accountNo') + ': ' + this.Bank__account.trim()}
                if(this.Bank__beneficiary){ bank += "\n" + this.$t('message.beneficiary') + ': ' + this.Bank__beneficiary.trim()}
                if(this.Bank__agent){ bank += "\n" + this.$t('message.agentBank') + ': ' + this.Bank__agent.trim()}
                if(this.Bank__agentswiftcode){ bank += "\n" + this.$t('message.swiftCode') + ': ' + this.Bank__agentswiftcode.trim()}
            }
            return bank
        },
        currencyFormatPrecision () {
            return this.currencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.formatPrecision
        },
        currencyPrecision () {
            return this.currencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.decimals
        },
        deliveryCaption() {
            return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_last
        },
        unitPriceFormatPrecision () {
            return this.currencies.find((currency) => currency.Currency.id === this.Contract__currency_id)?.Currency?.decimals;
        }
    },
    methods: {
        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                let reader = new FileReader()
                let _this = this
                reader.readAsDataURL(pdf.output('blob'));
                reader.onloadend = function () {
                    let file = reader.result;
                    api
                        .post('/contracts/pdf', {
                            pdf: {
                                contract_no: _this.Contract__title,
                                contract_id: _this.contractId,
                                file: file
                            },
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                _this.$emit('pdf-uploaded', response.data.url)
                            } else {
                                _this.$emit('pdf-not-uploaded')
                            }
                        })
                        .catch(() => {
                            _this.$emit('pdf-not-uploaded')
                        })
                }
            })
        },
        formatDate,
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        getCertifications() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/certifications",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCertification(certificationId, certificationInfo) {
            let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
            if(certificationObj){
                return certificationObj?.Certification[certificationInfo]
            }
            return ''
        },
        getCertificationLabel(salesDescription, certificationId){
            if(certificationId != null && certificationId != 0){
                let certificationText = this.certificationsNew.find(c => c.Certification.id == certificationId)?.Certification?.title

                let searchStrings =  ['FSC','PEFC','SVLK',certificationText];
                let stringFound = false

                searchStrings.forEach(string => {
                    if(salesDescription.indexOf(string) > 0) stringFound = true
                })

                if(stringFound){
                    return ''
                } else {
                    return ', ' + certificationText
                }

            } else {
                return ''
            }
        },
        getCurrency(currencyId, currencyKey) {
            let currencyObj = this.currencies.find(c => c.Currency.id == currencyId)
            if(currencyObj){
                return currencyObj?.Currency[currencyKey]
            }
            return ''
        },
        getCurrencies() {
            return new Promise((resolve, reject) => {
                log('get currencies')
                api
                    .get("/print/currencies",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getDescription(salesDescription, mcId, mcText) {
            if(mcId != null){
                let searchStrings =  ['AD','AST','Green','green','KD',mcText];
                let stringFound = false

                searchStrings.forEach(string => {
                    if(salesDescription.indexOf(string) > 0) stringFound = true
                })

                if(stringFound){
                    return salesDescription
                } else {
                    return salesDescription + ' ' + mcText
                }
            } else {
                return salesDescription
            }
        },
        getHsCode (val) {
            let productGroup = this.productGroupsNew.find(p => {
                if(Object.prototype.hasOwnProperty.call(p, 'header') == false && p.ProductGroup.id == val){
                    return true
                } else {
                    return  false
                }
            })
            if(productGroup?.ProductGroup.hscode){
                return ", " + this.$t('message.hsCodeForImport') + ": " + productGroup?.ProductGroup.hscode
            } else {
                return ''
            }
        },
        getHsCodeOld (val) {
            let productGroup = this.productGroupsNew.find(p => {
                if(Object.prototype.hasOwnProperty.call(p, 'header') == false && p.Productgroup.id == val){
                    return true
                } else {
                    return  false
                }
            })
            if(productGroup?.Productgroup.hscode){
                return ", " + this.$t('message.hsCodeForImport') + ": " + productGroup?.Productgroup.hscode
            } else {
                return ''
            }
        },
        getProductGroups() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/productgroups",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getTaxId () {
            let taxId = ''
            if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
            if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
            return taxId
        },
        loadContractById (contract_id) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/print/contracts/" + contract_id,{
                    })
                    .then(response => {
                        for(let key in response.data.data[0]){
                            self[key] = response.data.data[0][key]
                        }
                        resolve(contract_id)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        loadSalesItemsByContract (contract_id) {
            let self = this
            return new Promise((resolve, reject) => {
                api
                    .get("/print/contracts/" + contract_id + '/salesitems',{
                    })
                    .then(response => {
                        self.Salesitems = response.data.data
                        resolve(contract_id)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        nl2br,
        async updateTotals () {
            let amount = 0, quantities = []
            await this.Salesitems.forEach((item) => {
                if(item.type == 'credit') {
                    amount -= isNumeric(item.Salesitem.amount) ? roundTo(parseFloat(item.Salesitem.amount), this.currencyPrecision) : 0;
                } else {
                    amount += isNumeric(item.Salesitem.amount) ? roundTo(parseFloat(item.Salesitem.amount), this.currencyPrecision) : 0;
                }

                let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
                if(idx !== -1){
                    quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
                } else {
                    if(item.type == 'default') {
                        let obj = {'Total': {}}
                        obj['Total']['uofm'] = item.Measurement.title;
                        obj['Total']['qty'] = isNumeric(item.Salesitem.itemqty) ? parseFloat(item.Salesitem.itemqty) : 0;
                        quantities.push(obj)
                    }
                }
            })
            this.Salesitems__itemsTotalAmount = amount
            this.Salesitems__itemsGrandTotal = amount + parseFloat(this.Contract__adjustment)
            this.Salesitems__itemsTotalQty = quantities
            if(this.Payment__dprequired == 1 && this.Payment__amountinpercent > 0){
                this.depositRequired = this.Salesitems__itemsTotalAmount * (this.Payment__amountinpercent/100)
            }
        },
        uofmPrecision (uofm) {
            return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
        }
    },
    created() {
        this.$emit('pdf-uploading')
        this.getProductGroups()
            .then((productGroups)=>{
                this.productGroupsNew = productGroups
                this.getCertifications()
                    .then((certifications) => {
                        this.certificationsNew = certifications
                        this.getCurrencies()
                            .then((currencies) => {
                                this.currencies = currencies
                                this.loadContractById(this.contractId)
                                    .then((contract_id)=>{
                                        this.loadSalesItemsByContract(contract_id)
                                            .then(()=>{
                                                this.updateTotals()
                                                    .then(()=>{
                                                        this.loaded = true
                                                        this.$refs.html2Pdf.generatePdf()
                                                    })
                                                    .catch(() => {
                                                        this.$emit('pdf-not-uploaded')
                                                    })
                                            })
                                            .catch(() => {
                                                this.$emit('pdf-not-uploaded')
                                            })
                                    })
                                    .catch(() => {
                                        this.$emit('pdf-not-uploaded')
                                    })
                            })
                            .catch(() => {
                                this.$emit('pdf-not-uploaded')
                            })
                    })
                    .catch(() => {
                        this.$emit('pdf-not-uploaded')
                    })
            })
            .catch(() => {
                this.$emit('pdf-not-uploaded')
            })
    }
}
</script>

<style scoped>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .border-top-1 {
        border-top: 1px solid black !important;
    }
    .border-bottom-1 {
        border-bottom: 1px solid black !important;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .document-caption {
        font-size: 15px !important;
        line-height: 17px;
    }
    .footer-note {
        font-size: 8pt !important;
    }
    .header-title {
        font-size: 19pt !important;
        line-height: 24px;
        font-weight: bold !important;
    }
    .header-subtitle {
        font-size: 10pt !important;
    }
    .paper-a4 {
        height: 1065px;
        margin-left: 75px;
        margin-top: 35px;
        background-color: #FFFFFF !important;
        color: #000000 !important;
    }
    .specification-table table tr td {
        font-family:'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    .specification-table table tr.table-header td {
        font-family:'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 14px !important;
        vertical-align: top !important;
    }
    .specification-table table tr.table-row-main td {
        font-family:'AppicArial' !important;
        line-height:19px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 19px !important;
    }
    .specification-table table tr.table-row-sub td {
        font-family:'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 14px !important;
    }
    .specification-table table tr td.table-total {
        height: 8px !important;
    }
    .stamp-area {
        height: 100px;
    }
    .v-application{
        font-family:'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:2px !important;
        background-color: #FFFFFF !important;
    }
    .width-10-pct {
        width: 12% !important;
    }
    .width-12-pct {
        width: 12% !important;
    }
    .width-15-pct {
        width: 15% !important;
    }
    body {
        width: 18.5cm !important;
        margin: 0 !important;
    }
    * {
        text-rendering: geometricprecision !important;
    }
</style>